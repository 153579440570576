import { queryOptions, useQuery } from '@tanstack/react-query';

import { api } from '@/lib/api-client';
import type { QueryConfig } from '@/lib/react-query';
import type { Match } from '@/types/api';

export const getTournamentMatches = (): Promise<{
  total_count: number;
  matches: Match[];
}> => {
  return api.get(`/team_management/v1/matches`);
};

export const getTournamentMatchesQueryOptions = () => {
  return queryOptions({
    queryKey: ['matches'],
    queryFn: () => getTournamentMatches(),
  });
};

type UseTournamentMatchesQueryOptions = {
  queryConfig?: QueryConfig<typeof getTournamentMatchesQueryOptions>;
};

export const useTournamentMatches = ({
  queryConfig,
}: UseTournamentMatchesQueryOptions = {}) => {
  return useQuery({ ...getTournamentMatchesQueryOptions(), ...queryConfig });
};
