import { Spinner } from 'flowbite-react';

import { cn } from '@/utils/cn';

export const ContentLoader = ({ className }: { className?: string }) => {
  return (
    <div
      className={cn('flex h-48 w-full items-center justify-center', className)}
    >
      <Spinner size="lg" />
    </div>
  );
};
