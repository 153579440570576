import type { QueryClient } from '@tanstack/react-query';
import { Navigate, redirect, type LoaderFunctionArgs } from 'react-router-dom';

import { ProtectedRoute } from '@/lib/auth';

import { TeamManagerRoot } from './root';
import { matchesLoader } from './routes/matches';
import { playersLoader } from './routes/players';

export const teamManagerRoutes = (queryClient: QueryClient) => [
  {
    path: '/team-manager/login',
    lazy: async () => {
      const { LoginRoute } = await import('./routes/auth/login');
      return { Component: LoginRoute };
    },
  },
  {
    path: '/team-manager/login/code',
    lazy: async () => {
      const { LoginCodeRoute } = await import('./routes/auth/login-code');
      return { Component: LoginCodeRoute };
    },
  },
  {
    path: '/team-manager',
    element: (
      <ProtectedRoute>
        <TeamManagerRoot />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '',
        lazy: async () => {
          const { DashboardRoute } = await import('./routes/dashboard');
          return { Component: DashboardRoute };
        },
      },
      { path: ':teamId', element: <Navigate to={'matches'} /> },
      {
        path: ':teamId/matches',
        lazy: async () => {
          const { MatchesRoute } = await import('./routes/matches');
          return { Component: MatchesRoute };
        },
        loader: matchesLoader(queryClient),
      },
      // @deprecated
      {
        path: ':teamId/players',
        loader: ({ params }: LoaderFunctionArgs) =>
          redirect(`/team-manager/${params.teamId}/roster`),
      },
      {
        path: ':teamId/roster',
        lazy: async () => {
          const { PlayersRoute } = await import('./routes/players');
          return { Component: PlayersRoute };
        },
        loader: playersLoader(queryClient),
      },
    ],
  },
];
