import type { Team, TeamGroup } from '@/types/api';
import { cn } from '@/utils/cn';

const isTeam = (teamGroup: Team | TeamGroup): teamGroup is Team => {
  return (teamGroup as Team).team_group_id !== undefined;
};

export const TeamNameWithFlag = ({
  teamGroup,
  teams,
  className,
  inverse = false,
  short = false,
}: {
  className?: string;
  inverse?: boolean;
  short?: boolean;
  teamGroup: Team | TeamGroup;
  teams?: Team[];
}) => {
  let teamImage = null;

  if (teamGroup.country_code) {
    teamImage = (
      <span className={cn(`fi-${teamGroup.country_code}`, 'fi', className)} />
    );
  } else {
    const teamImageUrl = teams?.find(
      (team) => team.team_image_url,
    )?.team_image_url;

    if (teamImageUrl) {
      teamImage = (
        <img
          src={teamImageUrl}
          alt={`${teamGroup.name} logo`}
          className={cn('mx-auto w-full', className)}
        />
      );
    }
  }

  const name =
    short && isTeam(teamGroup)
      ? teamGroup.scoreboard_name || teamGroup.name
      : teamGroup.name;

  return (
    <span>
      {inverse ? (
        <>
          {teamImage} {name}
        </>
      ) : (
        <>
          {name} {teamImage}
        </>
      )}
    </span>
  );
};
