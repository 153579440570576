import { HiUser } from 'react-icons/hi2';

import { useBreakpoint } from '@/hooks/use-breakpoint';
import type { Player } from '@/types/api';

import { DeletePlayerModal } from './delete-player-modal';
import { EditPlayerModal } from './edit-player-modal';

const PlayerCard = ({ player }: { player: Player }) => {
  const { isBelowSm } = useBreakpoint('sm');

  return (
    <div className="flex gap-4 overflow-hidden rounded border border-gray-200 bg-white shadow-md dark:bg-gray-900 sm:flex-col sm:rounded-lg">
      <div className="relative flex aspect-[4/5] w-32 flex-col justify-center overflow-hidden sm:w-full">
        {player.profile_picture_url ? (
          <img
            src={player.profile_picture_url}
            alt={`${player.given_name} ${player.family_name}`}
            className="size-full object-cover object-center"
          />
        ) : (
          <HiUser className="h-auto w-full text-gray-300 sm:-mb-36" />
        )}
      </div>
      <div className="flex flex-1 flex-col justify-between gap-4">
        <div className="flex flex-col gap-4 p-4 text-right font-medium sm:flex-1 sm:flex-row sm:items-center sm:justify-between sm:py-0">
          <span className="text-2xl sm:text-base">#{player.player_number}</span>
          <span className="text-lg sm:text-base">
            {player.given_name} {player.family_name}
          </span>
        </div>

        <div className="flex items-center justify-between gap-3 p-4 pt-0">
          <EditPlayerModal player={player} textLabel={isBelowSm} />
          <DeletePlayerModal player={player} textLabel={isBelowSm} />
        </div>
      </div>
    </div>
  );
};

export const PlayerGrid = ({ players }: { players: Player[] }) => {
  return players.length > 0 ? (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-4">
      {players.map((player) => (
        <PlayerCard player={player} key={player.id} />
      ))}
    </div>
  ) : (
    <p className="text-center">
      You haven&apos;t added any players for this division yet.
    </p>
  );
};
