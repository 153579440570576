import { useMediaQuery } from 'react-responsive';
import resolveConfig from 'tailwindcss/resolveConfig';

const fullConfig = resolveConfig({ content: [''] });

const breakpoints = fullConfig?.theme?.screens || {
  xs: '480px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
};

export const useBreakpoint = <K extends string>(breakpoint: K) => {
  const value = breakpoints[breakpoint as keyof typeof breakpoints];
  const isAbove = useMediaQuery({ query: `(min-width: ${value})` });
  const capitalizedKey =
    breakpoint.charAt(0).toUpperCase() + breakpoint.slice(1);

  type KeyAbove = `isAbove${Capitalize<K>}`;
  type KeyBelow = `isBelow${Capitalize<K>}`;

  return {
    [breakpoint]: Number(String(value).replace(/[^0-9]/g, '')),
    [`isAbove${capitalizedKey}`]: isAbove,
    [`isBelow${capitalizedKey}`]: !isAbove,
  } as Record<K, number> & Record<KeyAbove | KeyBelow, boolean>;
};
