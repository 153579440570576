import { useMutation, useQueryClient } from '@tanstack/react-query';

import { api } from '@/lib/api-client';
import type { MutationConfig } from '@/lib/react-query';

import { getMatchesQueryOptions } from './get-matches';
import { getTournamentMatchesQueryOptions } from './get-tournament-matches';

export const confirmMatch = ({
  matchId,
  teamId,
}: {
  matchId: string;
  teamId: string;
}): Promise<void> => {
  return api.post(
    `/team_management/v1/teams/${teamId}/matches/${matchId}/confirm`,
  );
};

type useConfirmMatchOptions = {
  matchId?: string;
  teamId: string;
  mutationConfig?: MutationConfig<typeof confirmMatch>;
};

export const useConfirmMatch = ({
  teamId,
  mutationConfig,
}: useConfirmMatchOptions) => {
  const queryClient = useQueryClient();

  const { onSuccess, ...otherConfig } = mutationConfig ?? {};

  return useMutation({
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: getMatchesQueryOptions({ teamId }).queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: getTournamentMatchesQueryOptions().queryKey,
      });
      onSuccess?.(...args);
    },
    ...otherConfig,
    mutationFn: confirmMatch,
  });
};
