import type { QueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { useParams, type LoaderFunctionArgs } from 'react-router-dom';

import { ContentLoader } from '@/components/loaders/content-loader';
import {
  getMatchesQueryOptions,
  useMatches,
} from '@/features/matches/api/get-matches';
import { MatchesGrid } from '@/features/matches/components/matches-grid';
import { MatchesHeader } from '@/features/matches/components/matches-header';
import { MatchesTable } from '@/features/matches/components/matches-table';
import { filterMatches } from '@/features/matches/utils/filter-matches';
import { useTournament } from '@/features/tournaments/api/get-tournament';
import { useBreakpoint } from '@/hooks/use-breakpoint';

export const matchesLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const teamId = params.teamId as string;

    const query = getMatchesQueryOptions({ teamId });

    return (
      queryClient.getQueryData(query.queryKey) ??
      (await queryClient.fetchQuery(query))
    );
  };

export const MatchesRoute = () => {
  const params = useParams();
  const teamId = params.teamId as string;

  const { isBelowSm } = useBreakpoint('sm');
  const [matchFilter, setMatchFilter] = useState<string>('all');
  const matchesQuery = useMatches({ teamId });
  const tournamentQuery = useTournament();

  const safeSetMachFilter = useCallback(
    (newFilter: string) => {
      setMatchFilter((prevFilter) => {
        if (prevFilter === newFilter) {
          return 'all';
        }

        return newFilter;
      });
    },
    [setMatchFilter],
  );

  if (matchesQuery.isLoading || tournamentQuery.isLoading) {
    return <ContentLoader />;
  }

  if (!matchesQuery.data || !tournamentQuery.data) {
    return null;
  }

  const { total_count, matches } = matchesQuery.data;
  const { tournament } = tournamentQuery.data;

  const filteredMatches = filterMatches(matches, matchFilter);

  const showGrid = isBelowSm;

  return (
    <section className="flex flex-col gap-4 p-4">
      <MatchesHeader
        matchesCount={total_count}
        filteredMatchesCount={filteredMatches.length}
        matchFilter={matchFilter}
        setMatchFilter={safeSetMachFilter}
      />

      {showGrid ? (
        <MatchesGrid
          matches={filteredMatches}
          matchFilter={matchFilter}
          teamId={teamId}
          tournament={tournament}
        />
      ) : (
        <MatchesTable
          matches={filteredMatches}
          matchFilter={matchFilter}
          teamId={teamId}
          tournament={tournament}
        />
      )}
    </section>
  );
};
