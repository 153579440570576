import Cookies from 'js-cookie';
import {
  createContext,
  useCallback,
  useContext,
  useState,
  type PropsWithChildren,
} from 'react';

import { StorageKey } from '@/config/constants';

interface SidebarContextProps {
  desktop: {
    isCollapsed: boolean;
    setCollapsed(value: boolean): void;
    toggle(): void;
  };
  mobile: {
    isOpen: boolean;
    close(): void;
    toggle(): void;
  };
}

const SidebarContext = createContext<SidebarContextProps | null>(null);

export const SidebarProvider = ({
  initialCollapsed,
  children,
}: PropsWithChildren<{ initialCollapsed: boolean }>) => {
  const [isOpenMobile, setOpenMobile] = useState(false);
  const [isCollapsed, setCollapsed] = useState(initialCollapsed);

  const setCookie = (value: boolean) => {
    Cookies.set(
      StorageKey.SidebarCollapsed,
      JSON.stringify({ isCollapsed: value }),
    );
  };

  const handleSetCollapsed = (value: boolean) => {
    setCollapsed(value);
    setCookie(value);
  };

  const mobileClose = useCallback(() => {
    setOpenMobile(false);
  }, [setOpenMobile]);

  const mobileToggle = useCallback(() => {
    setOpenMobile((state) => !state);
  }, [setOpenMobile]);

  return (
    <SidebarContext.Provider
      value={{
        desktop: {
          isCollapsed,
          setCollapsed: handleSetCollapsed,
          toggle: () => handleSetCollapsed(!isCollapsed),
        },
        mobile: {
          isOpen: isOpenMobile,
          close: mobileClose,
          toggle: mobileToggle,
        },
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = (): SidebarContextProps => {
  const context = useContext(SidebarContext);

  if (!context) {
    throw new Error(
      'useSidebarContext must be used within the SidebarContext provider!',
    );
  }

  return context;
};
