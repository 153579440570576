import { useEffect, useState } from 'react';

import { ContentLoader } from '@/components/loaders/content-loader';
import { useBreakpoint } from '@/hooks/use-breakpoint';

import { usePlayers } from '../api/get-players';

import { PlayerGrid } from './player-grid';
import PlayerTable from './player-table';
import { PlayersHeader } from './players-header';

export const PlayersView = ({ teamId }: { teamId: string }) => {
  const { isBelowSm } = useBreakpoint('sm');
  const [showGrid, setGridShown] = useState(false);

  useEffect(() => {
    if (isBelowSm) {
      setGridShown(true);
    }
  }, [isBelowSm]);

  const playersQuery = usePlayers({ teamId });

  if (playersQuery.isLoading) {
    return <ContentLoader />;
  }

  if (!playersQuery.data) {
    return null;
  }

  const { players, total_count } = playersQuery.data;

  return (
    <section className="flex flex-col gap-4 p-4">
      <PlayersHeader
        teamId={teamId}
        playerCount={total_count}
        showGrid={showGrid}
        setShowGrid={setGridShown}
      />

      {showGrid ? (
        <PlayerGrid players={players} />
      ) : (
        <PlayerTable players={players} />
      )}
    </section>
  );
};
