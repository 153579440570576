'use client';

import { Avatar, Table } from 'flowbite-react';

import type { Player } from '@/types/api';

import { DeletePlayerModal } from './delete-player-modal';
import { EditPlayerModal } from './edit-player-modal';

const PlayerTable = ({ players }: { players: Player[] }) => {
  return (
    <div className="relative flex flex-col rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800">
      {' '}
      <Table
        className="w-full bg-white text-left text-sm text-gray-500 dark:text-gray-400"
        theme={{
          head: {
            base: 'bg-gray-100 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400',
            cell: { base: 'px-4 py-3' },
          },
          body: {
            base: 'sm:rounded-b-lg',
            cell: { base: 'px-4 py-3' },
          },
          row: {
            base: 'border-t hover:bg-gray-100 dark:border-gray-600 dark:hover:bg-gray-700',
          },
        }}
      >
        <Table.Head>
          <Table.HeadCell></Table.HeadCell>
          <Table.HeadCell>Player No.</Table.HeadCell>
          <Table.HeadCell>Given Name</Table.HeadCell>
          <Table.HeadCell>Family Name</Table.HeadCell>
          <Table.HeadCell></Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {players.length > 0 ? (
            players.map((player) => (
              <Table.Row key={player.id}>
                <Table.Cell className="w-16">
                  <Avatar
                    className="size-10"
                    img={
                      player.profile_picture_url
                        ? () => (
                            <img
                              className="size-10 rounded object-cover object-center"
                              alt={`${player.given_name} ${player.family_name}`}
                              src={player.profile_picture_url}
                            />
                          )
                        : undefined
                    }
                    alt={`${player.given_name} ${player.family_name}`}
                  />
                </Table.Cell>
                <Table.Cell>{player.player_number}</Table.Cell>
                <Table.Cell>{player.given_name}</Table.Cell>
                <Table.Cell>{player.family_name}</Table.Cell>
                <Table.Cell>
                  <div className="flex items-center justify-end gap-x-3 whitespace-nowrap">
                    <EditPlayerModal player={player} />
                    <DeletePlayerModal player={player} />
                  </div>
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={5}>
                <div className="p-8 text-center">
                  <p>
                    You haven&apos;t added any players for this division yet.
                  </p>
                </div>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  );
};

export default PlayerTable;
