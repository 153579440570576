import { Label } from 'flowbite-react';
import type { ReactNode } from 'react';
import type { FieldError } from 'react-hook-form';

type FieldWrapperProps = {
  children: ReactNode;
  className?: string;
  label?: string;
  error?: FieldError;
};

export type FieldWrapperPassthroughProps = Omit<
  FieldWrapperProps,
  'className' | 'children'
>;

export const FieldWrapper = (props: FieldWrapperProps) => {
  const { className, label, children } = props;

  return (
    <div className={className}>
      <Label>
        {label}
        <div className="mt-1">{children}</div>
      </Label>
    </div>
  );
};
