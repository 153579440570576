'use client';
import { Button, Checkbox, Label, Modal } from 'flowbite-react';
import { useState } from 'react';
import { HiPlus } from 'react-icons/hi2';

import { Form, Input } from '@/components/ui/form';
import { ProfileUploadField } from '@/components/ui/form/profile-upload-field';
import { useNotifications } from '@/components/ui/notifications';
import { useBreakpoint } from '@/hooks/use-breakpoint';

import { createPlayerInputSchema, useCreatePlayer } from '../api/create-player';

export const CreatePlayerModal = ({ teamId }: { teamId: string }) => {
  const { addNotification } = useNotifications();
  const [isOpen, setOpen] = useState(false);
  const [keepOpen, setKeepOpen] = useState(true);

  const createPlayerMutation = useCreatePlayer({
    teamId,
    mutationConfig: {
      onSuccess: () => {
        addNotification({ type: 'success', title: 'Team member was added' });

        if (!keepOpen) {
          setOpen(false);
        }
      },
    },
  });

  const { isBelowSm } = useBreakpoint('sm');

  return (
    <>
      <Button
        color="blue"
        className="p-0"
        onClick={() => setOpen(true)}
        fullSized={isBelowSm}
      >
        <div className="flex items-center gap-x-3">
          <HiPlus className="text-xl" />
          Add team member
        </div>
      </Button>
      <Modal dismissible onClose={() => setOpen(false)} show={isOpen}>
        <Modal.Header>Add new team member</Modal.Header>
        <Modal.Body>
          <Form
            id="create-player"
            onSubmit={async (values) => {
              await createPlayerMutation.mutateAsync({ teamId, data: values });

              return undefined;
            }}
            schema={createPlayerInputSchema}
          >
            {({ formState, register, getValues, setValue, watch }) => {
              return (
                <div className="flex flex-col gap-4 sm:flex-row sm:items-start sm:gap-12">
                  <ProfileUploadField name="profile_picture" />

                  <div className="flex flex-1 flex-col gap-4">
                    <Input
                      id="given-name"
                      label="Given name"
                      error={formState.errors['given_name']}
                      {...register('given_name')}
                    />
                    <Input
                      label="Family name"
                      error={formState.errors['family_name']}
                      {...register('family_name', { required: true })}
                      required
                    />

                    <Button.Group>
                      <Button
                        color={
                          !watch('player_number', '').startsWith('Staff')
                            ? 'blue'
                            : 'light'
                        }
                        onClick={() => {
                          const number = getValues('player_number') || '';
                          const hasNumber = number.split('-').length === 2;
                          number.startsWith('Staff') &&
                            setValue(
                              'player_number',
                              hasNumber ? number.split('-')[1] : '',
                              {
                                shouldTouch: true,
                              },
                            );
                        }}
                      >
                        Player
                      </Button>
                      <Button
                        color={
                          watch('player_number', '').startsWith('Staff')
                            ? 'blue'
                            : 'light'
                        }
                        onClick={() => {
                          const number = getValues('player_number') || '';
                          !number.startsWith('Staff') &&
                            setValue('player_number', `Staff-${number}`, {
                              shouldTouch: true,
                            });
                        }}
                      >
                        Staff
                      </Button>
                    </Button.Group>

                    <Input
                      className={
                        watch('player_number', '').startsWith('Staff')
                          ? 'hidden'
                          : 'block'
                      }
                      type="number"
                      label="Player number"
                      error={formState.errors['player_number']}
                      {...register('player_number')}
                    />
                  </div>
                </div>
              );
            }}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex w-full items-center justify-end gap-4">
            <div>
              <Checkbox
                color="blue"
                id="keep_open"
                checked={keepOpen}
                onChange={({ target: { checked } }) => setKeepOpen(checked)}
              />
              <Label htmlFor="keep_open" className="ml-2">
                Keep form open?
              </Label>
            </div>

            <Button
              color="blue"
              form="create-player"
              type="submit"
              disabled={createPlayerMutation.isPending}
            >
              Add team member
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
