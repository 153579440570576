import { queryOptions, useQuery } from '@tanstack/react-query';

import { api } from '@/lib/api-client';
import type { QueryConfig } from '@/lib/react-query';
import type { Match } from '@/types/api';

export const getMatches = ({
  teamId,
}: {
  teamId: string;
}): Promise<{
  total_count: number;
  matches: Match[];
}> => {
  return api.get(`/team_management/v1/teams/${teamId}/matches`);
};

export const getMatchesQueryOptions = ({ teamId }: { teamId: string }) => {
  return queryOptions({
    queryKey: ['matches', teamId],
    queryFn: () => getMatches({ teamId }),
  });
};

type UseMatchesQueryOptions = {
  teamId: string;
  queryConfig?: QueryConfig<typeof getMatchesQueryOptions>;
};

export const useMatches = ({ teamId, queryConfig }: UseMatchesQueryOptions) => {
  return useQuery({ ...getMatchesQueryOptions({ teamId }), ...queryConfig });
};
