import { Toast } from 'flowbite-react';
import {
  HiInformationCircle,
  HiExclamationCircle,
  HiCheckCircle,
  HiXCircle,
} from 'react-icons/hi2';

const icons = {
  info: (
    <HiInformationCircle className="size-6 text-blue-500" arria-hidden="true" />
  ),
  success: (
    <HiCheckCircle className="size-6 text-green-500" arria-hidden="true" />
  ),
  warning: (
    <HiExclamationCircle
      className="size-6 text-yellow-500"
      arria-hidden="true"
    />
  ),
  error: <HiXCircle className="size-6 text-red-500" arria-hidden="true" />,
};

export type NotificationProps = {
  notification: {
    id: string;
    type: keyof typeof icons;
    title: string;
    message?: string;
  };
  onDismiss: (id: string) => void;
};

export const Notification = ({
  notification: { id, type, title },
  onDismiss,
}: NotificationProps) => {
  return (
    <Toast>
      <div className="inline-flex size-8 shrink-0 items-center justify-center rounded-lg bg-cyan-100 text-cyan-500 dark:bg-cyan-800 dark:text-cyan-200">
        {icons[type]}
      </div>
      <div className="ml-3 text-sm font-normal">{title}</div>
      <Toast.Toggle onDismiss={() => onDismiss(id)} />
    </Toast>
  );
};
