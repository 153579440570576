import type { Match } from '@/types/api';

export const filterMatches = (matches: Match[], filter: string) => {
  switch (filter) {
    case 'action_needed':
      return matches.filter((match) => match.action_state);
    case 'upcoming':
      return matches.filter((match) =>
        ['generated', 'scheduled', 'seeded'].includes(match.match_state),
      );
    case 'finished':
      return matches.filter((match) => match.match_state === 'finished');
    default:
      return matches;
  }
};
