'use client';

import { Sidebar } from 'flowbite-react';
import type { ComponentProps, FC, HTMLAttributeAnchorTarget } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

export interface SidebarItem {
  href: string;
  target?: HTMLAttributeAnchorTarget;
  icon?: FC<ComponentProps<'svg'>>;
  label: string;
  items?: SidebarItem[];
  badge?: string;
  alwaysOpen?: boolean;
}

export interface SidebarItemProps extends SidebarItem {
  pathname: string;
}

export const SidebarElement = ({
  badge,
  href,
  icon,
  items,
  label,
  pathname,
  target,
  alwaysOpen,
}: SidebarItemProps) => {
  if (items) {
    const isOpen =
      alwaysOpen || items.some((item) => pathname.startsWith(item.href ?? ''));

    return (
      <Sidebar.Collapse
        icon={icon}
        label={label}
        open={isOpen}
        theme={{ list: 'space-y-2 py-2 [&>li>div]:w-full' }}
      >
        {items.map((item) => (
          <Sidebar.Item
            key={item.label}
            as={Link}
            to={item.href}
            target={item.target}
            className={twMerge(
              'justify-center [&>*]:font-normal',
              pathname === item.href && 'bg-gray-100 dark:bg-gray-700',
            )}
          >
            {item.label}
          </Sidebar.Item>
        ))}
      </Sidebar.Collapse>
    );
  }

  return (
    <Sidebar.Item
      as={Link}
      to={href}
      target={target}
      icon={icon}
      label={badge}
      className={twMerge(pathname === href && 'bg-gray-100 dark:bg-gray-700')}
    >
      {label}
    </Sidebar.Item>
  );
};
