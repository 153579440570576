import { useMutation, useQueryClient } from '@tanstack/react-query';

import { api } from '@/lib/api-client';
import type { MutationConfig } from '@/lib/react-query';

import { getPlayersQueryOptions } from './get-players';

export const deletePlayer = ({
  teamId,
  playerId,
}: {
  teamId: string;
  playerId: string;
}): Promise<void> => {
  return api.delete(`/team_management/v1/teams/${teamId}/players/${playerId}`);
};

type UseDeletePlayerOptions = {
  teamId: string;
  mutationConfig?: MutationConfig<
    (data: { playerId: string }) => Promise<void>
  >;
};

export const useDeletePlayer = ({
  teamId,
  mutationConfig,
}: UseDeletePlayerOptions) => {
  const queryClient = useQueryClient();

  const { onSuccess, ...otherConfig } = mutationConfig ?? {};

  return useMutation({
    onSuccess: (data, ...args) => {
      queryClient.invalidateQueries({
        queryKey: getPlayersQueryOptions(teamId).queryKey,
      });
      onSuccess?.(data, ...args);
    },
    ...otherConfig,
    mutationFn: ({ playerId }) => deletePlayer({ teamId, playerId }),
  });
};
