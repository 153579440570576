import Axios, { type InternalAxiosRequestConfig } from 'axios';

import { useNotifications } from '@/components/ui/notifications';
import { env } from '@/config/env';
import { getSubdomain } from '@/utils/get-subdomain';

const currentTournamentInterceptor = (config: InternalAxiosRequestConfig) => {
  if (config.headers) {
    config.headers['Scored-Tournament'] = getSubdomain();
  }

  return config;
};

const authRequestInterceptor = (config: InternalAxiosRequestConfig) => {
  if (config.headers) {
    config.headers.Accept = 'application/json';
  }

  config.withCredentials = true;
  return config;
};

export const api = Axios.create({ baseURL: env.API_URL });

api.interceptors.request.use(currentTournamentInterceptor);
api.interceptors.request.use(authRequestInterceptor);
api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response?.status === 401) {
      return Promise.resolve(null);
    }

    const message = error.response?.data?.message || error.message;
    useNotifications.getState().addNotification({
      type: 'error',
      title: message,
    });

    return Promise.reject(error);
  },
);
