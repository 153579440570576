import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import { api } from '@/lib/api-client';
import type { MutationConfig } from '@/lib/react-query';
import type { Player } from '@/types/api';

import { getPlayersQueryOptions } from './get-players';

export const createPlayerInputSchema = z.object({
  given_name: z.string(),
  family_name: z.string().min(1, 'Family name is required'),
  player_number: z
    .string()
    .regex(/^(\d+|Staff-\d*)$/, 'Player number must be a number'),
  profile_picture: z.instanceof(FileList).optional(),
});

export type CreatePlayerInput = z.infer<typeof createPlayerInputSchema>;

export const createPlayer = ({
  teamId,
  data,
}: {
  teamId: string;
  data: CreatePlayerInput;
}): Promise<Player> => {
  const formData = new FormData();

  formData.append('given_name', data.given_name);
  formData.append('family_name', data.family_name);

  if (data.player_number.startsWith('Staff')) {
    formData.append('player_number', 'Staff');
  } else {
    formData.append('player_number', data.player_number);
  }

  if (data.profile_picture?.[0]) {
    formData.append('profile_picture', data.profile_picture[0]);
  }

  return api.post(`/team_management/v1/teams/${teamId}/players`, formData);
};

type UseCreatePlayerOptions = {
  teamId: string;
  mutationConfig?: MutationConfig<typeof createPlayer>;
};

export const useCreatePlayer = ({
  teamId,
  mutationConfig,
}: UseCreatePlayerOptions) => {
  const queryClient = useQueryClient();

  const { onSuccess, ...otherConfig } = mutationConfig ?? {};

  return useMutation({
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: getPlayersQueryOptions(teamId).queryKey,
      });
      onSuccess?.(...args);
    },
    ...otherConfig,
    mutationFn: createPlayer,
  });
};
