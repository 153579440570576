import Cookies from 'js-cookie';
import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { StorageKey } from '@/config/constants';
import { MessageListProvider } from '@/lib/message-list-context';
import { SidebarProvider, useSidebarContext } from '@/lib/sidebar-context';

import { TeamManagerSidebar } from '../ui/sidebar/team-manager-sidebar';

const LayoutContent = ({ children }: { children: ReactNode }) => {
  const sidebar = useSidebarContext();

  return (
    <div
      id="main-content"
      className={twMerge(
        'relative h-full w-full overflow-y-auto bg-gray-50 dark:bg-gray-900',
        sidebar.desktop.isCollapsed ? 'lg:ml-16' : 'lg:ml-64',
      )}
    >
      {children}
    </div>
  );
};

export const TeamManagerLayout = ({ children }: { children: ReactNode }) => {
  const sidebarCollapsed = Boolean(Cookies.get(StorageKey.SidebarCollapsed));
  const messageListCollapsed = Boolean(
    Cookies.get(StorageKey.MessageListCollapsed),
  );

  return (
    <div className="flex flex-col items-start lg:flex-row">
      <SidebarProvider initialCollapsed={sidebarCollapsed}>
        <TeamManagerSidebar />

        <MessageListProvider initialCollapsed={messageListCollapsed}>
          <LayoutContent>{children}</LayoutContent>
        </MessageListProvider>
      </SidebarProvider>
    </div>
  );
};
