import './utils/instrumentation';

import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot, type RootOptions } from 'react-dom/client';

import App from './app';

import './index.css';

const root = document.getElementById('root');
if (!root) {
  throw new Error('Root element not found');
}

const options = {
  onRecoverableError: Sentry.reactErrorHandler(),
  // onCaughtError: Sentry.reactErrorHandler(),
  // onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
  //   console.warn('Uncaught error', error, errorInfo.componentStack);
  // }),
} satisfies RootOptions;

if (import.meta.env.MODE === 'development') {
  const { enableMocking } = await import('./testing/mocks');

  enableMocking().then(() => {
    createRoot(root, options).render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    );
  });
} else {
  createRoot(root, options).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}
