'use client';
import { Button, Modal } from 'flowbite-react';
import { useCallback, useState } from 'react';
import { HiPencilSquare } from 'react-icons/hi2';

import { Form, Input } from '@/components/ui/form';
import { ProfileUploadField } from '@/components/ui/form/profile-upload-field';
import { useNotifications } from '@/components/ui/notifications';
import type { Player } from '@/types/api';

import { updatePlayerInputSchema, useUpdatePlayer } from '../api/update-player';

export const EditPlayerModal = ({
  player,
  textLabel = false,
}: {
  player: Player;
  textLabel?: boolean;
}) => {
  const { addNotification } = useNotifications();
  const [profilePictureDeleted, setProfilePictureDeleted] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const onProfilePictureDelete = useCallback(() => {
    setProfilePictureDeleted(true);
  }, [setProfilePictureDeleted]);

  const updatePlayerMutation = useUpdatePlayer({
    mutationConfig: {
      onSuccess: ({ player }) => {
        addNotification({
          type: 'success',
          title: `${player.given_name} ${player.family_name} was updated`,
        });
        setOpen(false);
      },
    },
  });

  const attribution =
    player.player_number === 'Staff' ? 'staff member' : 'player';

  return (
    <>
      <Button
        size="sm"
        color="light"
        className="p-0 focus:ring-gray-300"
        onClick={() => setOpen(true)}
      >
        <div className="flex items-center gap-x-2">
          <HiPencilSquare className="size-5" />
          {textLabel && 'Edit'}
        </div>
      </Button>
      <Modal dismissible onClose={() => setOpen(false)} show={isOpen}>
        <Modal.Header>Edit {attribution}</Modal.Header>
        <Modal.Body>
          <Form
            id={`update-player-${player.id}`}
            onSubmit={async (values) => {
              await updatePlayerMutation.mutateAsync({
                playerId: player.id,
                teamId: player.team_id,
                data: {
                  ...values,
                  delete_profile_picture: profilePictureDeleted,
                },
              });
            }}
            schema={updatePlayerInputSchema}
            options={{
              defaultValues: { ...player, profile_picture: undefined },
            }}
          >
            {({ formState, register, getValues, setValue, watch }) => (
              <div className="flex flex-col gap-4 sm:flex-row sm:items-start sm:gap-12">
                <ProfileUploadField
                  name="profile_picture"
                  onDelete={onProfilePictureDelete}
                  existingProfilePicture={player.profile_picture_url}
                />

                <div className="flex flex-1 flex-col gap-4">
                  <Input
                    label="Given name"
                    error={formState.errors['given_name']}
                    {...register('given_name')}
                  />
                  <Input
                    label="Family name"
                    error={formState.errors['family_name']}
                    {...register('family_name')}
                  />

                  <Button.Group>
                    <Button
                      color={
                        !watch('player_number').startsWith('Staff')
                          ? 'blue'
                          : 'light'
                      }
                      onClick={() => {
                        const number = getValues('player_number');
                        const hasNumber = number.split('-').length === 2;
                        number.startsWith('Staff') &&
                          setValue(
                            'player_number',
                            hasNumber ? number.split('-')[1] : '',
                            {
                              shouldTouch: true,
                            },
                          );
                      }}
                    >
                      Player
                    </Button>
                    <Button
                      color={
                        watch('player_number').startsWith('Staff')
                          ? 'blue'
                          : 'light'
                      }
                      onClick={() => {
                        const number = getValues('player_number');
                        !number.startsWith('Staff') &&
                          setValue('player_number', `Staff-${number}`, {
                            shouldTouch: true,
                          });
                      }}
                    >
                      Staff
                    </Button>
                  </Button.Group>

                  <Input
                    className={
                      watch('player_number').startsWith('Staff')
                        ? 'hidden'
                        : 'block'
                    }
                    label="Player number"
                    error={formState.errors['player_number']}
                    {...register('player_number')}
                  />
                </div>
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex w-full items-center justify-end gap-4">
            <Button color="light" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              color="blue"
              form={`update-player-${player.id}`}
              type="submit"
              disabled={updatePlayerMutation.isPending}
            >
              Update {attribution}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
