import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import { api } from '@/lib/api-client';
import type { MutationConfig } from '@/lib/react-query';
import type { Player } from '@/types/api';

import { getPlayersQueryOptions } from './get-players';

export const updatePlayerInputSchema = z.object({
  given_name: z.string(),
  family_name: z.string().min(1, 'Family name is required'),
  player_number: z
    .string()
    .regex(/^(\d+|Staff-\d*)$/, 'Player number must be a number'),
  profile_picture: z.instanceof(FileList).optional(),
  delete_profile_picture: z.boolean().optional(),
});

export type UpdatePlayerInput = z.infer<typeof updatePlayerInputSchema>;

export const updatePlayer = ({
  teamId,
  playerId,
  data,
}: {
  teamId: string;
  playerId: string;
  data: UpdatePlayerInput;
}): Promise<{ player: Player }> => {
  const formData = new FormData();

  formData.append('given_name', data.given_name);
  formData.append('family_name', data.family_name);

  if (data.player_number.startsWith('Staff')) {
    formData.append('player_number', 'Staff');
  } else {
    formData.append('player_number', data.player_number);
  }

  if (data.profile_picture?.[0]) {
    formData.append('profile_picture', data.profile_picture[0]);
  } else if (data.delete_profile_picture) {
    formData.append('delete_profile_picture', 'true');
  }

  return api.patch(
    `/team_management/v1/teams/${teamId}/players/${playerId}`,
    formData,
  );
};

type UseUpdatePlayerOptions = {
  mutationConfig?: MutationConfig<typeof updatePlayer>;
};

export const useUpdatePlayer = ({
  mutationConfig,
}: UseUpdatePlayerOptions = {}) => {
  const queryClient = useQueryClient();

  const { onSuccess, ...otherConfig } = mutationConfig ?? {};

  return useMutation({
    onSuccess: (data, ...args) => {
      queryClient.invalidateQueries({
        queryKey: getPlayersQueryOptions(data.player.team_id).queryKey,
      });
      onSuccess?.(data, ...args);
    },
    ...otherConfig,
    mutationFn: updatePlayer,
  });
};
