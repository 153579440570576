import { Button } from 'flowbite-react';
import type { Dispatch, SetStateAction } from 'react';
import { HiMiniSquares2X2, HiTableCells } from 'react-icons/hi2';

import { CreatePlayerModal } from './create-player-modal';

export const PlayersHeader = ({
  teamId,
  playerCount,
  showGrid,
  setShowGrid,
}: {
  teamId: string;
  playerCount: number;
  showGrid: boolean;
  setShowGrid: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <div className="relative flex flex-col rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800">
      <div className="divide-y px-4 dark:divide-gray-700">
        <div className="flex flex-col space-y-3 py-3 md:flex-row md:items-center md:justify-between md:space-x-4 md:space-y-0">
          <div className="flex flex-1 items-center space-x-2">
            <h5>
              <span className="font-semibold dark:text-white">Team roster</span>
              <span className="ml-2 text-gray-500 dark:text-gray-400">
                {playerCount}{' '}
                {playerCount === 1 ? 'team member' : 'team members'}
              </span>
            </h5>
          </div>
          <div className="hidden shrink-0 flex-col items-start space-y-3 sm:flex md:flex-row md:items-center md:space-x-3 md:space-y-0 lg:justify-end">
            <Button
              color={showGrid ? 'blue' : 'light'}
              size="xs"
              onClick={() => setShowGrid(true)}
            >
              <HiMiniSquares2X2 className="size-5" />
            </Button>
            <Button
              color={showGrid ? 'light' : 'blue'}
              size="xs"
              onClick={() => setShowGrid(false)}
            >
              <HiTableCells className="size-5" />
            </Button>
          </div>
        </div>
        <div className="flex flex-col items-stretch justify-between space-y-3 py-4 md:flex-row md:items-center md:space-x-3 md:space-y-0">
          <div className="text-sm text-gray-900 dark:text-white">
            Add all players and staff members in your team here, to be able to
            select them for individual matches.
          </div>
          <div className="flex w-full shrink-0 flex-col items-stretch justify-end space-y-2 md:w-auto md:flex-row md:items-center md:space-x-3 md:space-y-0">
            <CreatePlayerModal teamId={teamId} />
          </div>
        </div>
      </div>
    </div>
  );
};
