import { Badge, Card } from 'flowbite-react';

import { TeamNameWithFlag } from '@/components/ui/team-name-with-flag';
import type { Match, Tournament } from '@/types/api';

import { MatchActions } from './match-actions';

const MatchCard = ({
  match,
  teamId,
  tournament,
}: {
  match: Match;
  teamId: string;
  tournament: Tournament;
}) => {
  return (
    <Card className="relative">
      <div className="flex items-center justify-between text-gray-600 dark:text-gray-300">
        <div>
          {Intl.DateTimeFormat('en-US', {
            timeZone: tournament?.timezone,
            dateStyle: 'short',
            timeStyle: 'short',
          }).format(new Date(match.scheduled_at))}
        </div>
        <div>{match.court_name}</div>
      </div>

      {match.match_state === 'in_progress' && (
        <div className="flex items-center justify-center">
          <Badge size="xl" color="info">
            Live
          </Badge>
        </div>
      )}

      <div className="flex items-center gap-4">
        <div className="flex flex-1 flex-col items-end gap-2">
          <div className="text-xl">
            {match.home_team ? (
              <TeamNameWithFlag teamGroup={match.home_team} short />
            ) : (
              'TBD'
            )}
          </div>
          <div className="text-4xl">
            {(match.match_state === 'in_progress' ||
              match.match_state === 'finished') && (
              <div>{match.home_team_score}</div>
            )}
          </div>
        </div>
        <div className="text-center">
          <div className="leading-7 text-gray-500">vs.</div>

          <div className="mt-2 text-2xl/10 text-gray-500">
            {(match.match_state === 'in_progress' ||
              match.match_state === 'finished') && <div>-</div>}
          </div>
        </div>
        <div className="flex flex-1 flex-col items-start gap-2">
          <div className="text-xl">
            {match.away_team ? (
              <TeamNameWithFlag teamGroup={match.away_team} inverse short />
            ) : (
              'TBD'
            )}
          </div>
          <div className="text-4xl">
            {(match.match_state === 'in_progress' ||
              match.match_state === 'finished') && (
              <div>{match.away_team_score}</div>
            )}
          </div>
        </div>
      </div>

      <MatchActions match={match} teamId={teamId} floatingDots />
    </Card>
  );
};

const EmtpyMatches = ({ matchFilter }: { matchFilter?: string }) => {
  let text = 'No matches found';

  switch (matchFilter) {
    case 'action_needed':
      text = "You're all caught up, no action required.";
      break;
    case 'upcoming':
      text = 'There are currently no upcoming matches for you to see.';
      break;
    case 'finished':
      text = 'There are no finished matches yet.';
      break;
  }

  return (
    <div className="flex items-center justify-center py-4 text-gray-500 dark:text-gray-400">
      {text}
    </div>
  );
};

export const MatchesGrid = ({
  matches,
  matchFilter,
  teamId,
  tournament,
}: {
  matches: Match[];
  matchFilter?: string;
  teamId: string;
  tournament: Tournament;
}) => {
  return (
    <div className="flex flex-col gap-4">
      {matches.length > 0 ? (
        matches.map((match) => (
          <MatchCard
            key={match.id}
            match={match}
            teamId={teamId}
            tournament={tournament}
          />
        ))
      ) : (
        <EmtpyMatches matchFilter={matchFilter} />
      )}
    </div>
  );
};
