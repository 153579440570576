import type { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { z } from 'zod';

import { api } from './api-client';
import { configureAuth } from './auth/configure-auth';

const getUser = () => {
  return api.get('/team_management/v1/me');
};

const requestToken = (data: RequestTokenInput) => {
  return api.post('/team_management/v1/token', data);
};

const code = (data: CodeInput) => {
  return api.post('/team_management/v1/token/otp_token', data);
};

const logout = () => {
  return api.delete('/team_management/v1/token');
};

export const requestTokenInputSchema = z.object({
  email: z.string().min(1, 'Required').email('Invalid email'),
});

export const codeInputSchema = z.object({
  email: z.string().min(1, 'Required').email('Invalid email'),
  otp: z.string().regex(/^\d+$/, 'Invalid code').length(6, 'Invalid code'),
});

export type RequestTokenInput = z.infer<typeof requestTokenInputSchema>;
export type CodeInput = z.infer<typeof codeInputSchema>;

const authConfig = {
  userFn: getUser,
  requestTokenFn: requestToken,
  codeFn: code,
  logoutFn: async () => {
    await logout();
  },
};

export const { useUser, useRequestToken, useLogout, useCode, AuthLoader } =
  configureAuth(authConfig);

export const ProtectedRoute = ({ children }: PropsWithChildren) => {
  const user = useUser();
  const location = useLocation();

  if (!user.data) {
    return (
      <Navigate
        to={`/team-manager/login?redirectTo=${encodeURIComponent(location.pathname)}`}
        replace
      />
    );
  }

  return children;
};
