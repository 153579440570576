import { TextInput } from 'flowbite-react';
import { forwardRef, type ElementRef } from 'react';
import OTPInputPrimitive from 'react-otp-input';

import { cn } from '@/utils/cn';

import { FormField } from './form';

const OTPInput = forwardRef<
  ElementRef<typeof OTPInputPrimitive>,
  { name: string }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(({ name, ...props }, ref) => {
  return (
    <FormField
      name={name}
      render={({ field: { ref, ...field } }) => (
        <OTPInputPrimitive
          numInputs={6}
          inputType="number"
          renderInput={({ className, ...props }) => (
            <TextInput
              className={cn(
                className,
                'mx-1 [&_input]:text-center [&_input]:text-xl',
              )}
              {...props}
            />
          )}
          skipDefaultStyles
          {...props}
          {...field}
        />
      )}
    />
  );
});
OTPInput.displayName = 'OTPInput';

export { OTPInput };
