import Cookies from 'js-cookie';
import {
  createContext,
  useContext,
  useState,
  type PropsWithChildren,
} from 'react';

import { StorageKey } from '@/config/constants';

interface MessageListContextProps {
  isCollapsed: boolean;
  setCollapsed(value: boolean): void;
  toggle(): void;
}

const MessageListContext = createContext<MessageListContextProps | null>(null);

export const MessageListProvider = ({
  initialCollapsed,
  children,
}: PropsWithChildren<{ initialCollapsed: boolean }>) => {
  const [isCollapsed, setCollapsed] = useState(initialCollapsed);

  const setCookie = (value: boolean) => {
    Cookies.set(
      StorageKey.MessageListCollapsed,
      JSON.stringify({ isCollapsed: value }),
    );
  };

  const handleSetCollapsed = (value: boolean) => {
    setCollapsed(value);
    setCookie(value);
  };

  return (
    <MessageListContext.Provider
      value={{
        isCollapsed,
        setCollapsed: handleSetCollapsed,
        toggle: () => handleSetCollapsed(!isCollapsed),
      }}
    >
      {children}
    </MessageListContext.Provider>
  );
};

export const useMessageListContext = (): MessageListContextProps => {
  const context = useContext(MessageListContext);

  if (!context) {
    throw new Error(
      'useMessageListContext must be used within the MessageListContext provider!',
    );
  }

  return context;
};
