import * as Sentry from '@sentry/react';
import type { QueryClient } from '@tanstack/react-query';
import { createBrowserRouter } from 'react-router-dom';

import { getTournamentQueryOptions } from '@/features/tournaments/api/get-tournament';

import { teamManagerRoutes } from './team-manager/routes';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const createRouter = (queryClient: QueryClient) => {
  return sentryCreateBrowserRouter([
    {
      path: '',
      children: [
        {
          path: '/',
          lazy: async () => {
            const { LandingRoute } = await import('./landing');
            return { Component: LandingRoute };
          },
        },
        {
          path: '/divisions/:divisionId',
          lazy: async () => {
            const { DivisionRoute } = await import('./division');
            return { Component: DivisionRoute };
          },
        },
        ...teamManagerRoutes(queryClient),
        {
          path: '*',
          lazy: async () => {
            const { NotFoundRoute } = await import('./not-found');
            return { Component: NotFoundRoute };
          },
        },
      ],
      loader: async () => {
        const query = getTournamentQueryOptions();

        return (
          queryClient.getQueryData(query.queryKey) ??
          (await queryClient.fetchQuery(query))
        );
      },
    },
  ]);
};
