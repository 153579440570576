import { Button, Modal } from 'flowbite-react';
import { useState } from 'react';
import { HiOutlineExclamationTriangle, HiTrash } from 'react-icons/hi2';

import { useNotifications } from '@/components/ui/notifications';
import type { Player } from '@/types/api';

import { useDeletePlayer } from '../api/delete-player';

export const DeletePlayerModal = ({
  player,
  textLabel = false,
}: {
  player: Player;
  textLabel?: boolean;
}) => {
  const { addNotification } = useNotifications();
  const [isOpen, setOpen] = useState(false);

  const deletePlayerMutation = useDeletePlayer({
    teamId: player.team_id,
    mutationConfig: {
      onSuccess: () => {
        addNotification({
          type: 'success',
          title: `${player.given_name} ${player.family_name} was removed`,
        });
        setOpen(false);
      },
    },
  });

  const attribution =
    player.player_number === 'Staff' ? 'staff member' : 'player';

  return (
    <>
      <Button
        size="sm"
        color="failure"
        className="p-0"
        onClick={() => setOpen(true)}
      >
        <div className="flex items-center gap-x-2">
          <HiTrash className="size-5" />
          {textLabel && 'Delete '}
        </div>
      </Button>
      <Modal show={isOpen} size="md" onClose={() => setOpen(false)} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="text-center">
            <HiOutlineExclamationTriangle className="mx-auto mb-4 size-14 text-gray-400 dark:text-gray-200" />
            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
              Are you sure you want to delete &quot;{player.given_name}{' '}
              {player.family_name}&quot;?
            </h3>
            <div className="flex justify-end gap-4">
              <Button color="gray" onClick={() => setOpen(false)}>
                Cancel
              </Button>

              <Button
                color="failure"
                disabled={deletePlayerMutation.isPending}
                onClick={() =>
                  deletePlayerMutation.mutate({ playerId: player.id })
                }
              >
                Delete {attribution}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
