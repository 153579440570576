import { queryOptions, useQuery } from '@tanstack/react-query';

import { api } from '@/lib/api-client';
import type { QueryConfig } from '@/lib/react-query';
import type { Tournament } from '@/types/api';

export const getTournament = (): Promise<{ tournament: Tournament }> => {
  return api.get(`/team_management/v1/tournament`);
};

export const getTournamentQueryOptions = () => {
  return queryOptions({
    queryKey: ['tournament'],
    queryFn: () => getTournament(),
  });
};

type UseTournamentQueryOptions = {
  queryConfig?: QueryConfig<typeof getTournamentQueryOptions>;
};

export const useTournament = ({
  queryConfig,
}: UseTournamentQueryOptions = {}) => {
  return useQuery({
    ...getTournamentQueryOptions(),
    ...queryConfig,
  });
};
