import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useLocation } from 'react-router-dom';

import { TeamManagerLayout } from '@/components/layouts/team-manager-layout';
import { FullScreenLoader } from '@/components/loaders/full-screen-loader';

export const TeamManagerRoot = () => {
  const location = useLocation();

  return (
    <TeamManagerLayout>
      <Suspense fallback={<FullScreenLoader />}>
        <ErrorBoundary
          key={location.pathname}
          fallback={<div>Something went wrong!</div>}
        >
          <Outlet />
        </ErrorBoundary>
      </Suspense>
    </TeamManagerLayout>
  );
};
