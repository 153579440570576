import { Button } from 'flowbite-react';

export const MatchesHeader = ({
  matchFilter,
  setMatchFilter,
  filteredMatchesCount,
  matchesCount,
}: {
  matchFilter: string;
  setMatchFilter: (filter: string) => void;
  filteredMatchesCount: number;
  matchesCount: number;
}) => {
  return (
    <div className="relative flex flex-col rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800">
      <div className="px-4">
        <div className="flex flex-col space-y-3 py-3 md:flex-row md:items-center md:justify-between md:space-x-4 md:space-y-0">
          <div className="flex flex-1 items-center space-x-2">
            <h5>
              <span className="font-semibold dark:text-white">Matches</span>
              <span className="ml-2 text-gray-500 dark:text-gray-400">
                {filteredMatchesCount}{' '}
                {filteredMatchesCount === 1 ? 'match' : 'matches'}{' '}
                {matchFilter !== 'all' && `(${matchesCount} total)`}
              </span>
            </h5>
          </div>

          <Button.Group>
            <Button
              size="xs"
              color={matchFilter === 'action_needed' ? 'blue' : 'gray'}
              onClick={() => setMatchFilter('action_needed')}
            >
              Action needed
            </Button>
            <Button
              size="xs"
              color={matchFilter === 'upcoming' ? 'blue' : 'gray'}
              onClick={() => setMatchFilter('upcoming')}
            >
              Upcoming
            </Button>
            <Button
              size="xs"
              color={matchFilter === 'finished' ? 'blue' : 'gray'}
              onClick={() => setMatchFilter('finished')}
            >
              Finished
            </Button>
            <Button
              size="xs"
              color={matchFilter === 'all' ? 'blue' : 'gray'}
              onClick={() => setMatchFilter('all')}
            >
              All
            </Button>
          </Button.Group>
        </div>
      </div>
    </div>
  );
};
