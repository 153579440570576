import { Button, Drawer, Sidebar, Tooltip } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { HiBars3, HiMiniArrowRightOnRectangle } from 'react-icons/hi2';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { ContentLoader } from '@/components/loaders/content-loader';
import { useTeamGroup } from '@/features/team-groups/api/get-team-group';
import { useTeams } from '@/features/teams/api/get-teams';
import { useTournament } from '@/features/tournaments/api/get-tournament';
import { useLogout } from '@/lib/auth';
import { useSidebarContext } from '@/lib/sidebar-context';

import { SidebarElement, SidebarItem } from './sidebar-item';
import { SidebarTournamentInfo } from './sidebar-tournament-info';

const BottomMenu = ({ isCollapsed }: { isCollapsed?: boolean }) => {
  const navigate = useNavigate();
  const logout = useLogout();

  const performLogout = async () => {
    await logout.mutateAsync();
    navigate('/');
  };

  return (
    <div
      className={twMerge(
        'flex items-center justify-center gap-4',
        isCollapsed && 'flex-col',
      )}
    >
      {/* <Tooltip content="User settings">
        <Link href="/team-manager/user/settings">
          <span className="sr-only">User settings</span>
          <HiCog6Tooth className="h-6 w-6" />
        </Link>
      </Tooltip> */}
      <Tooltip content="Log out">
        <Link to="" onClick={() => performLogout()}>
          <span className="sr-only">Log out</span>
          <HiMiniArrowRightOnRectangle className="size-6" />
        </Link>
      </Tooltip>
    </div>
  );
};

const DesktopSidebar = () => {
  const { pathname } = useLocation();
  const { isCollapsed, setCollapsed } = useSidebarContext().desktop;
  const [showPreview, setShowPreview] = useState(isCollapsed);

  const teamsQuery = useTeams();
  const teamGroupQuery = useTeamGroup();
  const tournamentQuery = useTournament();

  if (
    teamsQuery.isLoading ||
    teamGroupQuery.isLoading ||
    tournamentQuery.isLoading
  ) {
    return (
      <Sidebar
        aria-label="Sidebar with multi-level dropdown example"
        collapsed={isCollapsed}
        className={twMerge(
          'fixed inset-y-0 left-0 z-20 flex h-full shrink-0 flex-col border-r border-gray-200 duration-75 dark:border-gray-700 lg:flex [&>div]:bg-white [&>div]:dark:bg-gray-900',
          isCollapsed && 'hidden w-16',
        )}
        id="sidebar"
      >
        <ContentLoader />
      </Sidebar>
    );
  }

  if (!teamsQuery.data || !teamGroupQuery.data || !tournamentQuery.data) {
    return null;
  }

  const { teams } = teamsQuery.data;
  const { team_group: teamGroup } = teamGroupQuery.data;
  const { tournament } = tournamentQuery.data;

  const teamList: SidebarItem[] = teams.map((team) => {
    return {
      href: `/team-manager/${team.id}`,
      label:
        team.name.startsWith(teamGroup.name) ||
        teamGroup.name.startsWith(team.name)
          ? team.division_name
          : `${team.name} (${team.division_name})`,
      items: [
        { href: `/team-manager/${team.id}/matches`, label: 'Matches' },
        { href: `/team-manager/${team.id}/roster`, label: 'Roster' },
      ],
    };
  });

  const preview = {
    enable: () => {
      if (!isCollapsed) {
        return;
      }

      setShowPreview(true);
      setCollapsed(false);
    },
    disable: () => {
      if (!showPreview) {
        return;
      }

      setCollapsed(true);
    },
  };

  return (
    <Sidebar
      onMouseEnter={preview.enable}
      onMouseLeave={preview.disable}
      aria-label="Sidebar with multi-level dropdown example"
      collapsed={isCollapsed}
      className={twMerge(
        'fixed inset-y-0 left-0 z-20 flex h-full shrink-0 flex-col border-r border-gray-200 duration-75 dark:border-gray-700 lg:flex [&>div]:bg-white [&>div]:dark:bg-gray-900',
        isCollapsed && 'hidden w-16',
      )}
      id="sidebar"
    >
      <div className="flex h-full flex-col justify-between">
        <div className="py-2">
          <SidebarTournamentInfo
            teamGroup={teamGroup}
            teams={teams}
            tournament={tournament}
          />

          <Sidebar.Items className="mt-5 border-t border-gray-200 pt-5 dark:border-gray-700">
            <Sidebar.ItemGroup className="mt-0 border-t-0 pb-1 pt-0">
              <Sidebar.Item
                as={Link}
                to={'/team-manager'}
                className={twMerge(
                  'justify-center [&>*]:font-normal',
                  pathname === '/team-manager' &&
                    'bg-gray-100 dark:bg-gray-700',
                )}
              >
                Dashboard
              </Sidebar.Item>
              {teamList.map((item) =>
                pathname.startsWith(item.href) ? (
                  <SidebarElement
                    href={item.href}
                    key={item.label}
                    pathname={pathname}
                    label={item.label}
                    items={item.items}
                    alwaysOpen={true}
                  />
                ) : (
                  <Sidebar.Item
                    key={item.label}
                    as={Link}
                    to={item.href}
                    target={item.target}
                    className={twMerge(
                      'justify-center [&>*]:font-normal',
                      pathname.startsWith(item.href) &&
                        'bg-gray-100 dark:bg-gray-700',
                    )}
                  >
                    {item.label}
                  </Sidebar.Item>
                ),
              )}
            </Sidebar.ItemGroup>
          </Sidebar.Items>
        </div>

        <BottomMenu isCollapsed={isCollapsed} />
      </div>
    </Sidebar>
  );
};

const MobileSidebar = () => {
  const { pathname } = useLocation();
  const { isOpen, toggle, close } = useSidebarContext().mobile;

  const teamsQuery = useTeams();
  const teamGroupQuery = useTeamGroup();
  const tournamentQuery = useTournament();

  useEffect(() => {
    close();
  }, [close, pathname]);

  if (
    teamsQuery.isLoading ||
    teamGroupQuery.isLoading ||
    tournamentQuery.isLoading
  ) {
    return <ContentLoader />;
  }

  if (!teamsQuery.data || !teamGroupQuery.data || !tournamentQuery.data) {
    return null;
  }

  const { teams } = teamsQuery.data;
  const { team_group: teamGroup } = teamGroupQuery.data;
  const { tournament } = tournamentQuery.data;

  const teamList: SidebarItem[] = teams.map((team) => {
    return {
      href: `/team-manager/${team.id}`,
      label:
        team.name.startsWith(teamGroup.name) ||
        teamGroup.name.startsWith(team.name)
          ? team.division_name
          : `${team.name} (${team.division_name})`,
      items: [
        { href: `/team-manager/${team.id}/matches`, label: 'Matches' },
        { href: `/team-manager/${team.id}/players`, label: 'Roster' },
      ],
    };
  });

  return (
    <>
      <div className="flex items-center gap-2 p-2">
        <Button
          color="light"
          onClick={() => toggle()}
          className="border-0 bg-transparent"
        >
          <HiBars3 className="size-6" />
        </Button>
        <div className="min-w-0 flex-1 truncate text-xl font-bold">
          {tournament.name}
        </div>
      </div>
      <Drawer
        open={isOpen}
        onClose={() => close()}
        className="w-64 bg-gray-50 dark:bg-gray-900"
      >
        <Drawer.Header titleIcon={() => <></>} />
        <Drawer.Items className="-m-4 h-full">
          <Sidebar className="-mr-8 w-full [&>div]:bg-transparent">
            <div className="flex h-full flex-col justify-between">
              <div className="py-2">
                <SidebarTournamentInfo
                  teamGroup={teamGroup}
                  teams={teams}
                  tournament={tournament}
                />

                <Sidebar.Items className="mt-5 border-t border-gray-200 pt-5 dark:border-gray-700">
                  <Sidebar.ItemGroup className="mt-0 border-t-0 pb-1 pt-0">
                    <Sidebar.Item
                      as={Link}
                      to={'/team-manager'}
                      className={twMerge(
                        'justify-center [&>*]:font-normal',
                        pathname === '/team-manager' &&
                          'bg-gray-100 dark:bg-gray-700',
                      )}
                    >
                      Dashboard
                    </Sidebar.Item>
                    {teamList.map((item, index) => (
                      <SidebarElement
                        href={item.href}
                        key={item.label}
                        pathname={pathname}
                        label={item.label}
                        items={item.items}
                        alwaysOpen={
                          pathname.startsWith(item.href) ||
                          (pathname === '/team-manager' && index === 0)
                        }
                      />
                    ))}
                  </Sidebar.ItemGroup>
                </Sidebar.Items>
              </div>

              <BottomMenu />
            </div>
          </Sidebar>
        </Drawer.Items>
      </Drawer>
    </>
  );
};

export const TeamManagerSidebar = () => {
  return (
    <>
      <div className="block w-full lg:hidden">
        <MobileSidebar />
      </div>
      <div className="hidden lg:block">
        <DesktopSidebar />
      </div>
    </>
  );
};
