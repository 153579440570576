import { Alert, Button, Modal } from 'flowbite-react';
import { useState } from 'react';

import { TeamNameWithFlag } from '@/components/ui/team-name-with-flag';
import type { Match, Tournament } from '@/types/api';

import { useConfirmMatch } from '../../api/confirm-match';

export const ConfirmationModal = ({
  match,
  teamId,
  tournament,
  isOpen,
  onClose,
}: {
  match: Match;
  tournament?: Tournament;
  teamId: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const confirmMatch = useConfirmMatch({ teamId });
  const [error, setError] = useState<string | null>(null);

  const onConfirm = async () => {
    try {
      setError(null);
      await confirmMatch.mutateAsync({ matchId: match.id, teamId });

      onClose();
    } catch (error) {
      setError("Couldn't confirm match, please try again.");
    }
  };

  return (
    <Modal show={isOpen} onClose={onClose}>
      <Modal.Header>Confirm result for {match.match_reference}</Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          {error && <Alert color="failure">{error}</Alert>}
        </div>

        <p className="pb-4 text-sm text-gray-900 dark:text-white">
          Please confirm the result for the{' '}
          <strong>{match.division_name}</strong> match at{' '}
          <strong>
            {Intl.DateTimeFormat('en-US', {
              timeZone: tournament?.timezone,
              dateStyle: 'short',
              timeStyle: 'short',
            }).format(new Date(match.scheduled_at))}
          </strong>{' '}
          on <strong>{match.court_name}</strong>.
        </p>

        <div className="flex items-center justify-center gap-4 text-xl">
          <TeamNameWithFlag teamGroup={match.home_team} />
          <div className="text-gray-900 dark:text-white">
            {match.home_team_score}
          </div>
          <div className="text-gray-900 dark:text-white">:</div>
          <div className="text-gray-900 dark:text-white">
            {match.away_team_score}
          </div>
          <TeamNameWithFlag teamGroup={match.away_team} inverse />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex w-full items-center justify-end gap-4">
          <Button color="light" onClick={onClose}>
            Cancel
          </Button>
          <Button color="blue" form="line-up" onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
