import { Dropdown, Label } from 'flowbite-react';
import { useEffect, useRef, useState, type ChangeEvent } from 'react';
import {
  HiArrowPath,
  HiCloudArrowUp,
  HiEllipsisVertical,
  HiTrash,
} from 'react-icons/hi2';

import { FormField } from './form';

type ProfileUploadFieldProps = {
  existingProfilePicture?: string;
  onChange: (value: FileList | null) => void;
  onDelete?: () => void;
  value?: FileList;
};

const ProfileUploadFieldInternal = ({
  onChange,
  onDelete,
  existingProfilePicture,
  value,
}: ProfileUploadFieldProps) => {
  const [imageUrl, setImageUrl] = useState<string | null>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (fileInputRef.current) {
      const file = fileInputRef.current?.files;

      onChange(file);
    }
  }, [imageUrl, onChange]);

  useEffect(() => {
    if (value?.[0]) {
      setImageUrl(URL.createObjectURL(value[0]));
    } else {
      setImageUrl(existingProfilePicture);
    }
  }, [existingProfilePicture, value]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]?.type.startsWith('image/')) {
      setImageUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const removeImage = () => {
    setImageUrl(null);

    // Notify form that we want to delete existing profile picture
    onDelete?.();

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const openFileSelect = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="group relative flex items-center justify-center">
      {imageUrl ? (
        <div className="relative h-80 w-64 overflow-hidden rounded-lg border border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600">
          <img
            className="size-full object-cover object-center"
            src={imageUrl}
            alt="Player/Staff profile"
          />
          <Dropdown
            label=""
            renderTrigger={() => (
              <div className="absolute right-2 top-2 z-10 flex size-8 cursor-pointer items-center justify-center rounded-full bg-black/70 text-gray-50/50 hover:text-gray-50 group-hover:flex sm:hidden">
                <HiEllipsisVertical className="size-6" />
              </div>
            )}
          >
            <Dropdown.Item icon={HiArrowPath} onClick={openFileSelect}>
              Change image
            </Dropdown.Item>
            <Dropdown.Item icon={HiTrash} onClick={removeImage}>
              Remove image
            </Dropdown.Item>
          </Dropdown>
        </div>
      ) : (
        <Label
          htmlFor="profile_picture"
          className="relative flex h-80 w-64 cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
        >
          <div className="flex flex-col items-center justify-center pb-6 pt-5">
            <HiCloudArrowUp className="mb-4 size-8 text-gray-500 dark:text-gray-400" />
            <p className="mb-2 px-4 text-center text-sm text-gray-500 dark:text-gray-400">
              <span className="font-semibold">Click to upload</span> or drag and
              drop player/staff profile picture
            </p>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              PNG or JPG
            </p>
          </div>
        </Label>
      )}
      <input
        ref={fileInputRef}
        accept="image/*"
        id="profile_picture"
        type="file"
        className="absolute inset-0 opacity-0"
        onChange={handleChange}
      />
    </div>
  );
};

export const ProfileUploadField = ({
  name,
  ...props
}: {
  name: string;
} & Omit<ProfileUploadFieldProps, 'onChange' | 'value'>) => {
  return (
    <FormField
      name={name}
      render={({ field: { onChange, value } }) => (
        <ProfileUploadFieldInternal
          {...props}
          onChange={onChange}
          value={value}
        />
      )}
    />
  );
};
