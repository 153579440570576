import { queryOptions, useQuery } from '@tanstack/react-query';

import { api } from '@/lib/api-client';
import type { QueryConfig } from '@/lib/react-query';

export const getTeam = ({ teamId }: { teamId: string }) => {
  return api.get(`/team_management/v1/teams/${teamId}`);
};

export const getTeamQueryOptions = (teamId: string) => {
  return queryOptions({
    queryKey: ['teams', teamId],
    queryFn: () => getTeam({ teamId }),
  });
};

type UseTeamQueryOptions = {
  teamId: string;
  queryConfig?: QueryConfig<typeof getTeamQueryOptions>;
};

export const useTeam = ({ queryConfig, teamId }: UseTeamQueryOptions) => {
  return useQuery({
    ...getTeamQueryOptions(teamId),
    ...queryConfig,
  });
};
