import { TextInput, type TextInputProps } from 'flowbite-react';
import { forwardRef, type ElementRef } from 'react';

import {
  FieldWrapper,
  type FieldWrapperPassthroughProps,
} from './field-wrapper';

export type InputProps = TextInputProps & FieldWrapperPassthroughProps;

const Input = forwardRef<ElementRef<typeof TextInput>, InputProps>(
  ({ className, label, error, ...props }, ref) => {
    return (
      <FieldWrapper className={className} label={label} error={error}>
        <TextInput
          ref={ref}
          {...props}
          color={error && 'failure'}
          helperText={error?.message}
        />
      </FieldWrapper>
    );
  },
);
Input.displayName = 'Input';

export { Input };
