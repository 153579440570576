import type { Team, TeamGroup, Tournament } from '@/types/api';

import { TeamNameWithFlag } from '../team-name-with-flag';

export const SidebarTournamentInfo = ({
  teamGroup,
  teams,
  tournament,
}: {
  teamGroup?: TeamGroup;
  teams?: Team[];
  tournament?: Tournament;
}) => {
  if (!teamGroup || !teams || !tournament) {
    return null;
  }

  return (
    <div className="text-center text-gray-500 dark:text-gray-400">
      {tournament.logo_url && (
        <div className="px-4">
          <img
            src={tournament.logo_url}
            alt={tournament.name}
            className="mx-auto mb-4 w-full"
          />
        </div>
      )}

      <h3 className="text-lg font-medium leading-tight tracking-tight text-gray-900 dark:text-white">
        <a href="#">{tournament.name}</a>
      </h3>
      <h4 className="mt-4 text-lg text-gray-700 dark:text-gray-300">
        <TeamNameWithFlag
          className="mr-2"
          teamGroup={teamGroup}
          teams={teams}
        />
      </h4>
    </div>
  );
};
