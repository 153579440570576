import dayjs from 'dayjs';
import { Button, Dropdown } from 'flowbite-react';
import { useState } from 'react';
import { HiEllipsisHorizontal } from 'react-icons/hi2';

import { useTournament } from '@/features/tournaments/api/get-tournament';
import type { Match } from '@/types/api';
import { cn } from '@/utils/cn';

import { ConfirmationModal } from './modals/confirmation-modal';
import { LineUpModal } from './modals/line-up-modal';

export const MatchActions = ({
  match,
  teamId,
  floatingDots = false,
}: {
  match: Match;
  teamId: string;
  floatingDots?: boolean;
}) => {
  const [lineUpModalOpen, setLineUpModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const tournamentQuery = useTournament();

  if (tournamentQuery.isLoading) {
    return null;
  }

  if (!tournamentQuery.data) {
    return null;
  }

  const { tournament } = tournamentQuery.data;

  const buttons = (
    <>
      {match.action_state === 'confirmation_missing' && (
        <Button
          size={floatingDots ? 'md' : 'xs'}
          color="blue"
          onClick={() => setConfirmationModalOpen(true)}
        >
          Confirm
        </Button>
      )}
      {match.action_state === 'lineup_missing' && (
        <Button
          size={floatingDots ? 'md' : 'xs'}
          color="light"
          onClick={() => setLineUpModalOpen(true)}
        >
          Pick line-up
        </Button>
      )}
    </>
  );

  const isBeforeMatch =
    new Date(match.scheduled_at).getTime() > new Date().getTime();

  const dropdownButtons = [
    match.match_state !== 'finished' && isBeforeMatch && (
      <Dropdown.Item
        onClick={() => setLineUpModalOpen(true)}
        className="whitespace-nowrap"
      >
        <span className={cn(floatingDots && 'text-lg')}>Edit line-up</span>
      </Dropdown.Item>
    ),
    match.match_state === 'finished' &&
      dayjs().diff(dayjs(match.finished_at), 'hours') < 24 && (
        <Dropdown.Item className="whitespace-nowrap">
          <span className={cn(floatingDots && 'text-lg')}>Dispute match</span>
        </Dropdown.Item>
      ),
  ].filter(Boolean);

  return (
    <>
      <div
        className={cn(
          'flex items-center gap-4',
          floatingDots ? 'justify-center' : 'justify-end',
        )}
      >
        {buttons}

        <div className={cn(floatingDots && 'absolute bottom-3 right-4')}>
          <Dropdown
            label=""
            placement="bottom-end"
            renderTrigger={() => (
              <div className={cn(dropdownButtons.length === 0 && 'hidden')}>
                <HiEllipsisHorizontal
                  className={cn(
                    'text-xl hover:cursor-pointer',
                    floatingDots && 'text-3xl',
                  )}
                />
              </div>
            )}
          >
            {dropdownButtons}
          </Dropdown>
        </div>
      </div>

      <LineUpModal
        isOpen={lineUpModalOpen}
        onClose={() => setLineUpModalOpen(false)}
        matchId={match.id}
        teamId={teamId}
      />

      <ConfirmationModal
        isOpen={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        match={match}
        tournament={tournament}
        teamId={teamId}
      />
    </>
  );
};
