import { Button, Checkbox, Label, Modal, TextInput } from 'flowbite-react';

import { ContentLoader } from '@/components/loaders/content-loader';
import { Form } from '@/components/ui/form';
import { useNotifications } from '@/components/ui/notifications';
import { usePlayers } from '@/features/players/api/get-players';
import { cn } from '@/utils/cn';

import { useMatchPartcipants } from '../../api/get-match-participants';
import {
  updateMatchParticipantsInputSchema,
  useUpdateMatchParticipants,
} from '../../api/update-match-participants';

export const LineUpModal = ({
  teamId,
  matchId,
  isOpen,
  onClose,
}: {
  matchId: string;
  teamId: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { addNotification } = useNotifications();
  const playersQuery = usePlayers({ teamId });
  const matchParticipantsQuery = useMatchPartcipants({
    matchId,
    teamId,
    queryConfig: { enabled: isOpen },
  });
  const updateMutation = useUpdateMatchParticipants({
    matchId,
    teamId,
    mutationConfig: {
      onSuccess: () => {
        addNotification({
          type: 'success',
          title: `Line-up was saved`,
        });
        onClose();
      },
    },
  });

  if (playersQuery.isLoading && matchParticipantsQuery.isLoading) {
    return <ContentLoader />;
  }

  if (!playersQuery.data || !matchParticipantsQuery.data) {
    return null;
  }

  const { players: roster } = playersQuery.data;
  const { participants } = matchParticipantsQuery.data;

  const playerIds = participants
    .filter((p) => p.participant_type === 'player')
    .map((p) => p.player_id);
  const staffIds = participants
    .filter((p) => p.participant_type === 'staff')
    .map((p) => p.player_id);
  const retrieverNames = participants
    .filter((p) => p.participant_type === 'retriever')
    .map((p) => p.name);

  // TODO: Make this configurable in backend

  const rosterPlayers = roster.filter(
    (player) => player.player_number !== 'Staff',
  );
  const rosterStaff = roster.filter(
    (player) => player.player_number === 'Staff',
  );

  return (
    <Modal size="7xl" show={isOpen} onClose={onClose} dismissible>
      <Modal.Header>Line-Up</Modal.Header>
      <Modal.Body>
        <Form
          id="line-up"
          onSubmit={async (data) => {
            await updateMutation.mutateAsync({ matchId, teamId, data });

            const result = await matchParticipantsQuery.refetch();
            if (!result.data) {
              return;
            }

            const playerIds = result.data.participants
              .filter((p) => p.participant_type === 'player')
              .map((p) => p.player_id);
            const staffIds = result.data.participants
              .filter((p) => p.participant_type === 'staff')
              .map((p) => p.player_id);
            const retrieverNames = result.data.participants
              .filter((p) => p.participant_type === 'retriever')
              .map((p) => p.name);

            return {
              players: playerIds,
              staff: staffIds,
              retrievers: retrieverNames,
            };
          }}
          options={{
            defaultValues: {
              players: playerIds,
              staff: staffIds,
              retrievers: retrieverNames,
            },
          }}
          schema={updateMatchParticipantsInputSchema}
        >
          {({ register, watch }) => (
            <>
              <div className="flex flex-col lg:flex-row lg:divide-x">
                <div className="lg:flex-1 lg:pr-4">
                  <h5 className="mb-1 mt-4 font-bold">Players (max. 12)</h5>
                  <div className="flex flex-col divide-y">
                    {rosterPlayers.map((player) => {
                      const disabled =
                        watch('players')?.length >= 12 &&
                        !watch('players').includes(player.id);

                      return (
                        <div
                          key={player.id}
                          className="flex items-center gap-2"
                        >
                          <Checkbox
                            id={`player_${player.id}`}
                            value={player.id}
                            {...register('players')}
                            disabled={disabled}
                          />
                          <Label
                            htmlFor={`player_${player.id}`}
                            className={cn(
                              'flex gap-2',
                              disabled && 'opacity-50',
                            )}
                          >
                            <div className="w-8 p-3 text-gray-500">
                              #{player.player_number}
                            </div>
                            <div className="p-3">
                              {player.given_name} {player.family_name}
                            </div>
                          </Label>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="lg:flex-1 lg:px-4">
                  <h5 className="mb-1 mt-4 font-bold">Staff (max. 4)</h5>
                  <div className="flex flex-col divide-y">
                    {rosterStaff.map((staffMember) => {
                      const disabled =
                        watch('staff')?.length >= 4 &&
                        !watch('staff').includes(staffMember.id);
                      return (
                        <div
                          key={staffMember.id}
                          className="flex items-center gap-2"
                        >
                          <Checkbox
                            id={`staff_${staffMember.id}`}
                            value={staffMember.id}
                            {...register('staff')}
                            disabled={disabled}
                          />
                          <Label
                            htmlFor={`staff_${staffMember.id}`}
                            className={cn(
                              'flex gap-2',
                              disabled && 'opacity-50',
                            )}
                          >
                            <div className="p-3">
                              {staffMember.given_name} {staffMember.family_name}
                            </div>
                          </Label>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="lg:flex-1 lg:pl-4">
                  <h5 className="mb-1 mt-4 font-bold">Retrievers</h5>
                  <div className="flex flex-col gap-2">
                    <TextInput
                      placeholder="Retriever 1"
                      {...register('retrievers.0')}
                    />
                    <TextInput
                      placeholder="Retriever 2"
                      {...register('retrievers.1')}
                    />
                    <TextInput
                      placeholder="Retriever 3"
                      {...register('retrievers.2')}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex w-full items-center justify-end gap-4">
          <Button color="light" onClick={onClose}>
            Cancel
          </Button>
          <Button color="blue" form="line-up" type="submit">
            Save
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
