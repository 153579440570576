import { queryOptions, useQuery } from '@tanstack/react-query';

import { api } from '@/lib/api-client';
import type { QueryConfig } from '@/lib/react-query';
import type { Player } from '@/types/api';

export const getPlayers = async ({
  teamId,
}: {
  teamId: string;
}): Promise<{ total_count: number; players: Player[] }> => {
  return api.get(`/team_management/v1/teams/${teamId}/players`);
};

export const getPlayersQueryOptions = (teamId: string) => {
  return queryOptions({
    queryKey: ['players', teamId],
    queryFn: () => getPlayers({ teamId }),
  });
};

type UsePlayersQueryOptions = {
  teamId: string;
  queryConfig?: QueryConfig<typeof getPlayersQueryOptions>;
};

export const usePlayers = ({ queryConfig, teamId }: UsePlayersQueryOptions) => {
  return useQuery({
    ...getPlayersQueryOptions(teamId),
    ...queryConfig,
  });
};
