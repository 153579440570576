import { queryOptions, useQuery } from '@tanstack/react-query';

import { api } from '@/lib/api-client';
import type { QueryConfig } from '@/lib/react-query';
import type { Participant } from '@/types/api';

export const getMatchParticipants = ({
  matchId,
  teamId,
}: {
  matchId: string;
  teamId: string;
}): Promise<{
  total_count: number;
  participants: Participant[];
}> => {
  return api.get(
    `/team_management/v1/teams/${teamId}/matches/${matchId}/participants`,
  );
};

export const getMatchParticipantsQueryOptions = ({
  matchId,
  teamId,
}: {
  matchId: string;
  teamId: string;
}) => {
  return queryOptions({
    queryKey: ['match-participants', matchId, teamId],
    queryFn: () => getMatchParticipants({ matchId, teamId }),
  });
};

type UseMatchesQueryOptions = {
  matchId: string;
  teamId: string;
  queryConfig?: QueryConfig<typeof getMatchParticipantsQueryOptions>;
};

export const useMatchPartcipants = ({
  matchId,
  teamId,
  queryConfig,
}: UseMatchesQueryOptions) => {
  return useQuery({
    ...getMatchParticipantsQueryOptions({ matchId, teamId }),
    ...queryConfig,
  });
};
