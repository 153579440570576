import { Label as LabelPrimitive } from 'flowbite-react';
import {
  forwardRef,
  type ComponentPropsWithoutRef,
  type ElementRef,
} from 'react';

const Label = forwardRef<
  ElementRef<typeof LabelPrimitive>,
  ComponentPropsWithoutRef<typeof LabelPrimitive>
>(({ className, ...props }, ref) => {
  return <LabelPrimitive ref={ref} className={className} {...props} />;
});
Label.displayName = LabelPrimitive.displayName;

export { Label };
