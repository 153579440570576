import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import { api } from '@/lib/api-client';
import type { MutationConfig } from '@/lib/react-query';

import { getMatchParticipantsQueryOptions } from './get-match-participants';
import { getMatchesQueryOptions } from './get-matches';
import { getTournamentMatchesQueryOptions } from './get-tournament-matches';

export const updateMatchParticipantsInputSchema = z.object({
  players: z.array(z.string()).max(12),
  staff: z.array(z.string()).max(4),
  retrievers: z.array(z.string()).max(3),
});

export type CreateMatchParticipantsInput = z.infer<
  typeof updateMatchParticipantsInputSchema
>;

export const updateMatchParticipants = ({
  matchId,
  teamId,
  data,
}: {
  matchId: string;
  teamId: string;
  data: CreateMatchParticipantsInput;
}): Promise<void> => {
  const participants: {
    player_id?: string;
    name?: string;
    participant_type: string;
  }[] = [];

  data.players.forEach((player) => {
    participants.push({ player_id: player, participant_type: 'player' });
  });

  data.staff.forEach((staff) => {
    participants.push({ player_id: staff, participant_type: 'staff' });
  });

  data.retrievers.forEach((retriever) => {
    participants.push({ name: retriever, participant_type: 'retriever' });
  });

  return api.put(
    `/team_management/v1/teams/${teamId}/matches/${matchId}/participants`,
    { participants },
  );
};

type useCreateMatchParticipantsOptions = {
  matchId: string;
  teamId: string;
  mutationConfig?: MutationConfig<typeof updateMatchParticipants>;
};

export const useUpdateMatchParticipants = ({
  matchId,
  teamId,
  mutationConfig,
}: useCreateMatchParticipantsOptions) => {
  const queryClient = useQueryClient();

  const { onSuccess, ...otherConfig } = mutationConfig ?? {};

  return useMutation({
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: getMatchesQueryOptions({ teamId }).queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: getTournamentMatchesQueryOptions().queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: getMatchParticipantsQueryOptions({
          matchId,
          teamId,
        }).queryKey,
      });
      onSuccess?.(...args);
    },
    ...otherConfig,
    mutationFn: updateMatchParticipants,
  });
};
