import { queryOptions, useQuery } from '@tanstack/react-query';

import { api } from '@/lib/api-client';
import type { QueryConfig } from '@/lib/react-query';
import type { TeamGroup } from '@/types/api';

export const getTeamGroup = (): Promise<{ team_group: TeamGroup }> => {
  return api.get(`/team_management/v1/team_group`);
};

export const getTeamGroupQueryOptions = () => {
  return queryOptions({
    queryKey: ['teamGroup'],
    queryFn: () => getTeamGroup(),
  });
};

type UseTeamGroupQueryOptions = {
  queryConfig?: QueryConfig<typeof getTeamGroupQueryOptions>;
};

export const useTeamGroup = ({
  queryConfig,
}: UseTeamGroupQueryOptions = {}) => {
  return useQuery({
    ...getTeamGroupQueryOptions(),
    ...queryConfig,
  });
};
