import type { QueryClient } from '@tanstack/react-query';
import { useParams, type LoaderFunctionArgs } from 'react-router-dom';

import { ContentLoader } from '@/components/loaders/content-loader';
import { getPlayersQueryOptions } from '@/features/players/api/get-players';
import { PlayersView } from '@/features/players/components/players-view';
import { getTeamQueryOptions, useTeam } from '@/features/teams/api/get-team';

export const playersLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const teamId = params.teamId as string;

    const teamQuery = getTeamQueryOptions(teamId);
    const playersQuery = getPlayersQueryOptions(teamId);

    const promises = [
      queryClient.getQueryData(teamQuery.queryKey) ??
        (await queryClient.fetchQuery(teamQuery)),
      queryClient.getQueryData(playersQuery.queryKey) ??
        (await queryClient.fetchQuery(playersQuery)),
    ];

    const [team, players] = await Promise.all(promises);

    return { team, players };
  };

export const PlayersRoute = () => {
  const params = useParams();
  const teamId = params.teamId as string;

  const teamQuery = useTeam({ teamId });

  if (teamQuery.isLoading) {
    return <ContentLoader />;
  }

  if (!teamQuery.data) {
    return null;
  }

  return <PlayersView teamId={teamId} />;
};
