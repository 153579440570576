import { Badge, Table } from 'flowbite-react';

import { TeamNameWithFlag } from '@/components/ui/team-name-with-flag';
import type { Match, Tournament } from '@/types/api';

import { MatchActions } from './match-actions';

const MatchRow = ({
  match,
  teamId,
  tournament,
}: {
  match: Match;
  teamId: string;
  tournament: Tournament;
}) => {
  const opponent =
    match.home_team?.id === teamId ? match.away_team : match.home_team;

  return (
    <Table.Row>
      <Table.Cell>{match.match_reference}</Table.Cell>
      <Table.Cell>
        {Intl.DateTimeFormat('en-US', {
          timeZone: tournament?.timezone,
          dateStyle: 'short',
          timeStyle: 'short',
        }).format(new Date(match.scheduled_at))}
      </Table.Cell>
      <Table.Cell>{match.court_name}</Table.Cell>
      <Table.Cell>
        vs. {opponent ? <TeamNameWithFlag teamGroup={opponent} /> : 'TBD'}
      </Table.Cell>
      <Table.Cell>
        {match.match_state === 'in_progress' && (
          <Badge color="info">Live</Badge>
        )}
        {match.match_state === 'finished' && (
          <div>
            {match.home_team_score} : {match.away_team_score}
          </div>
        )}
      </Table.Cell>
      <Table.Cell>
        <MatchActions match={match} teamId={teamId} />
      </Table.Cell>
    </Table.Row>
  );
};

const EmtpyMatchRow = ({ matchFilter }: { matchFilter?: string }) => {
  let text = 'No matches found';

  switch (matchFilter) {
    case 'action_needed':
      text = "You're all caught up, no action required.";
      break;
    case 'upcoming':
      text = 'There are currently no upcoming matches for you to see.';
      break;
    case 'finished':
      text = 'There are no finished matches yet.';
      break;
  }

  return (
    <Table.Row>
      <Table.Cell colSpan={8}>
        <div className="flex items-center justify-center py-4 text-gray-500 dark:text-gray-400">
          {text}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export const MatchesTable = ({
  matches,
  matchFilter,
  tournament,
  teamId,
}: {
  matches: Match[];
  matchFilter?: string;
  tournament: Tournament;
  teamId: string;
}) => {
  return (
    <div className="relative flex flex-col rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800">
      <Table
        className="w-full bg-white text-left text-sm text-gray-500 dark:text-gray-400"
        theme={{
          head: {
            base: 'bg-gray-100 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400',
            cell: { base: 'px-4 py-3' },
          },
          body: {
            base: 'sm:rounded-b-lg',
            cell: { base: 'px-4 py-3' },
          },
          row: {
            base: 'border-t hover:bg-gray-100 dark:border-gray-600 dark:hover:bg-gray-700',
          },
        }}
      >
        <Table.Head>
          <Table.HeadCell>Match #</Table.HeadCell>
          <Table.HeadCell>Scheduled At</Table.HeadCell>
          <Table.HeadCell>Court</Table.HeadCell>
          <Table.HeadCell>Opponent</Table.HeadCell>
          <Table.HeadCell>Score</Table.HeadCell>
          <Table.HeadCell></Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {matches.length > 0 ? (
            matches.map((match) => (
              <MatchRow
                key={match.id}
                match={match}
                teamId={teamId}
                tournament={tournament}
              />
            ))
          ) : (
            <EmtpyMatchRow matchFilter={matchFilter} />
          )}
        </Table.Body>
      </Table>
    </div>
  );
};
