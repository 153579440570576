import {
  useMutation,
  useQuery,
  useQueryClient,
  type MutationFunction,
  type QueryFunction,
  type QueryKey,
  type UseMutationOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { useCallback, type PropsWithChildren } from 'react';

export interface ReactQueryAuthConfig<
  User,
  RequestTokenCredentials,
  CodeCredentials,
> {
  userFn: QueryFunction<User, QueryKey>;
  requestTokenFn: MutationFunction<User, RequestTokenCredentials>;
  codeFn: MutationFunction<User, CodeCredentials>;
  logoutFn: MutationFunction<void, void>;
  userKey?: QueryKey;
}

export const configureAuth = <
  User,
  Error,
  RequestTokenCredentials,
  RegisterCredentials,
>(
  config: ReactQueryAuthConfig<
    User,
    RequestTokenCredentials,
    RegisterCredentials
  >,
) => {
  const {
    userFn,
    userKey = ['authenticated-user'],
    requestTokenFn,
    codeFn,
    logoutFn,
  } = config;

  const useUser = (
    options?: Omit<
      UseQueryOptions<User, Error, User, QueryKey>,
      'queryKey' | 'queryFn'
    >,
  ) => useQuery({ queryKey: userKey, queryFn: userFn, ...options });

  const useRequestToken = (
    options?: Omit<
      UseMutationOptions<User, Error, RequestTokenCredentials>,
      'mutationFn'
    >,
  ) => {
    return useMutation<User, Error, RequestTokenCredentials>({
      mutationFn: requestTokenFn,
      ...options,
      onSuccess: (user, ...rest) => {
        options?.onSuccess?.(user, ...rest);
      },
    });
  };

  const useCode = (
    options?: Omit<
      UseMutationOptions<User, Error, RegisterCredentials>,
      'mutationFn'
    >,
  ) => {
    const queryClient = useQueryClient();

    const setUser = useCallback(
      (data: User | null) => queryClient.setQueryData(userKey, data),
      [queryClient],
    );

    return useMutation<User, Error, RegisterCredentials>({
      mutationFn: codeFn,
      ...options,
      onSuccess: (user, ...rest) => {
        setUser(user);
        options?.onSuccess?.(user, ...rest);
      },
    });
  };

  const useLogout = (options?: UseMutationOptions<void, Error, void>) => {
    const queryClient = useQueryClient();

    const setUser = useCallback(
      (data: User | null) => queryClient.setQueryData(userKey, data),
      [queryClient],
    );

    return useMutation({
      mutationFn: logoutFn,
      ...options,
      onSuccess: (...args) => {
        setUser(null);
        options?.onSuccess?.(...args);
      },
    });
  };

  const AuthLoader = ({
    children,
    renderLoading,
    renderUnauthenticated,
    renderError = (error: Error) => <>{JSON.stringify(error)}</>,
  }: PropsWithChildren<{
    renderLoading: () => JSX.Element;
    renderUnauthenticated?: () => JSX.Element;
    renderError?: (error: Error) => JSX.Element;
  }>) => {
    const { isSuccess, isFetched, status, data, error } = useUser();

    if (isSuccess) {
      if (renderUnauthenticated && !data) {
        return renderUnauthenticated();
      }

      return <>{children}</>;
    }

    if (!isFetched) {
      return renderLoading();
    }

    if (status == 'error') {
      return renderError(error);
    }

    return null;
  };

  return {
    useUser,
    useRequestToken,
    useCode,
    useLogout,
    AuthLoader,
  };
};
